.page-container {
    background-image: url('../Images/login_bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    transition: opacity 0.5s;
}

.fade-out {
    opacity: 0;
}

.form-container {
    padding: 2rem;
    width: 100%;
    max-width: 500px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 15vh;
}

.heading {
    font-size: 2.5rem;
    font-weight: 700;
    color: white;
    text-align: center;
    margin-bottom: 4rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    font-family: "Comfortaa", sans-serif;
}

.input-field {
    background-color: rgba(255, 255, 255, 0.3);
    color: rgb(0, 0, 0);
    border-radius: 25px;
    padding: 0.75rem;
    font-size: 1rem;
    margin-bottom: 1rem;
    padding-left: 1rem;
    font-family: "Comfortaa", sans-serif;
    width: 100%;
    max-width: 100%;
}

.input-field:focus {
    outline: 2px solid rgba(163, 223, 231, 0.5);
    outline-offset: 2px;
}

.submit-button {
    margin-top: 1rem;
    border-radius: 25px;
    padding: 0.75rem;
    font-size: 1rem;
    background-color: rgba(46, 79, 18, 0.5);
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    font-family: "Comfortaa", sans-serif;
    width: 100%;
    max-width: 100%;
}

.register-button {
    margin-top: 1rem;
    border-radius: 25px;
    padding: 0.75rem;
    font-size: 1rem;
    background-color: rgba(18, 40, 79, 0.5);
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    font-family: "Comfortaa", sans-serif;
    width: 100%;
    max-width: 100%;
}