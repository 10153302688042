.admin-bg {
    background-color: #f3e3c4;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.bg-transparent {
    background-color: 'transparent';
}

.text-font {
    font-family: 'Comfortaa', sans-serif;
}

.admin-row {
    margin-bottom: 4rem;
}

.heading {
    font-size: 2.5rem;
    font-weight: 700;
    color: white;
    margin-bottom: 4rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.form-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
}

.form-group label {
    flex: 1;
    margin-right: 10px;
}

.form-group input,
.form-group select {
    flex: 2;
}

.amount-display {
    margin-left: 20px;
}

.hover-row {
    position: relative;
}

.hover-row::after {
    content: attr(data-description);
    position: absolute;
    left: 100%;
    top: 100%;
    background: #286565;
    color: #fff;
    padding: 5px;
    border-radius: 3px;
    white-space: nowrap;
    transform: translateY(5px) translateX(-105%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease;
    pointer-events: none;
    z-index: 1;
    font-size: 12px;
}

.hover-row:hover::after {
    opacity: 1;
    visibility: visible;
}