.investor-bg {
    background-color: #e8f1ea;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.bg-transparent {
    background-color: 'transparent';
}

.text-font {
    font-family: 'Comfortaa', sans-serif;
}

.number-font {
    font-family: 'Roboto', sans-serif;
}

.col-relative {
    position: relative;
    z-index: 0;
}

.hover-row {
    position: relative;
}

.hover-row::after {
    content: attr(data-description);
    position: absolute;
    left: 100%;
    top: 100%;
    background: #286565;
    color: #fff;
    padding: 5px;
    border-radius: 3px;
    white-space: nowrap;
    transform: translateY(5px) translateX(-105%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease;
    pointer-events: none;
    z-index: 1;
    font-size: 12px;
}

.hover-row:hover::after {
    opacity: 1;
    visibility: visible;
}

.table-container {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
}

.table thead {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #f8f9fa;
    z-index: 1;
    text-align: center;
}

.table th,
.table td {
    border: 2px solid #ffffff;
    text-align: center;
}

.table thead th {
    background-color: #4BC0C0;
    border-bottom: 2px solid #ffffff;
}

.properties-square-card {
    aspect-ratio: 1 / 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.properties-card-img {
    object-fit: cover;
    height: 100%;
}

.properties-position-relative {
    height: 100%;
    position: relative;
}

.properties-overlay {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 0 0 0.25rem 0.25rem;
    padding: 1rem;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.properties-overlay .card-title {
    color: #fff;
    margin: 0;
    padding: 0;
    font-family: 'Comfortaa', sans-serif;
}